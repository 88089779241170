<template>
  <v-app-bar color="white" height="75" app hide-on-scroll>
    <v-toolbar-title>
      <a :href="mainAppUrl">
        <img :src="brandingLogo"
             alt="Logo" class="logo-img" height="50" width="60"/>
      </a>
    </v-toolbar-title>
    <v-spacer></v-spacer>

    <button @click="openQuoteDrawer()" class="mr-3">
      <v-badge dot color="error" v-model="notification">
        <v-icon>mdi-bell</v-icon>
      </v-badge>
    </button>

    <!-- <button v-if="$vuetify.breakpoint.mdAndUp" class="primary-button mr-5" @click="navTo('term')">SP TERM</button>
    <button v-if="$vuetify.breakpoint.mdAndUp" class="primary-button mr-5" @click="navTo('whole')">SP WHOLE</button> -->
    <!-- <v-app-bar-nav-icon
      class="hidden-md-and-up"
      color="#0063C2"
      @click="openDrawer"
    /> -->
    <v-app-bar-nav-icon color="#0063C2" @click="openDrawer" />
  </v-app-bar>
</template>

<script>
export default {
  name: "AppHeader",
  props: { notification: { required: true, type: Boolean } },

  data: () => ({
    drawer: false,
    mpToken: "",
    brandingLogo: "https://lifemib.com/assets/images/logo.svg",
    mainAppUrl: `${process.env.VUE_APP_MAIN_APP_URL}/pro/activities/leads/all?page=1`,
  }),
  computed: {
    currentPath() {
      return this.$route;
    },
  },
  created() {
    if (this.currentPath.query.id) {
      this.mpToken = this.currentPath.query.id;
      try {
        let decodedToken = JSON.parse(
          atob(this.currentPath.query.id.split(".")[1])
        );
        if(decodedToken.user.agency === 'Simple Options Agency') {
          this.brandingLogo = 'https://lifemib.com/assets/images/logo.svg';
        }
      } catch (error) {

        console.debug(` -------------------------------------------------------`);
        console.debug(`file: index.vue 👀 line 53 👀 created 👀 error`, error);
        console.debug(` -------------------------------------------------------`);

      }
    }
  },

  methods: {
    navTo(path) {
      switch (path) {
        case "home":
          window.open(`https://consultation.1vlp.com/`);
          break;
        case "term":
          this.$router.push("/" + "?id=" + this.mpToken);
          break;
        case "whole":
          this.$router.push("/fe" + "?id=" + this.mpToken);
          break;
        default:
          break;
      }
    },

    openDrawer() {
      this.$emit("openDrawer", this.drawer);
    },
    openQuoteDrawer() {
      this.$emit("openQuoteDrawer", true);
    },
  },
};
</script>

<style lang="css" slot-scope>
.AppHeader {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #052d49;
  color: white;
}
.logo-img {
  width: 83px;
  cursor: pointer;
  padding-top: 8px;
}
.primary-button,
.primary-button::after {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.primary-button {
  background: none;
  border: 1px solid #fff;
  color: #0063c2;
  display: block;
  font-size: 18px;
  padding: 15px 10px;
  position: relative;
  text-transform: uppercase;
}

.primary-button::before,
.primary-button::after {
  background: #f1bd40;
  content: "";
  position: absolute;
  z-index: -1;
}

.primary-button:hover {
  color: white;
}

.primary-button::after {
  height: 100%;
  left: 0;
  top: 0;
  width: 0;
}

.primary-button:hover:after {
  width: 100%;
}
</style>
