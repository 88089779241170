import axios from 'axios';
const Api = axios.create({
    //baseURL: 'http://localhost:4000/',
    // baseURL: 'http://143.244.175.250/api'
    baseURL: `${process.env.VUE_APP_SP_API_URL}/api`
});

export default {
     get(data, token){
         if(token == null || token == '' || token == undefined){
            token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im5pY2tAbGlmZW1pYi5jb20iLCJmbmFtZSI6Ik5pY2siLCJsbmFtZSI6IkR1ZGFqIiwicm9sZSI6MSwiaWF0IjoxNjI0ODg2NTcxLCJleHAiOjI2MjU0OTEzNzF9.5IkY4kkE4h59Zx5o0AchFGnoS3DmNsNps9fTqNxLfnE";
         }
        const h = {Authorization: "Bearer " + token}

        const x = parseFilters(data);
        console.log("x",x);
        return Api.post('quote/filter',parseFilters(data),{headers:h})
     },
     getQuote(odata, token){
        var data = {...odata}
        if(token == null || token == '' || token == undefined){
            token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im5pY2tAbGlmZW1pYi5jb20iLCJmbmFtZSI6Ik5pY2siLCJsbmFtZSI6IkR1ZGFqIiwicm9sZSI6MSwiaWF0IjoxNjI0ODg2NTcxLCJleHAiOjI2MjU0OTEzNzF9.5IkY4kkE4h59Zx5o0AchFGnoS3DmNsNps9fTqNxLfnE";
         }
        const h = {Authorization: "Bearer " + token}

        var dob = new Date(data.dob);
        var dob_month = dob.getMonth();

        var dob_year = dob.getFullYear();
        var curr_year = new Date();
        var curr_month = curr_year.getMonth();

        curr_year = curr_year.getFullYear();
        var age = curr_year - dob_year;

        if(curr_month<dob_month)
        age = age -1

          data.provider_id == '' ? delete data.provider_id : '';
          /*const pi = data.provider._id;

          pi == '' ? delete data.provider_id : '';
          data.provider_id == '' ? delete data.provider_id : data.product_id = pi;*/

          data.product_id == '' ? delete data.product_id : '';
          data.age = age;


        if(Object.prototype.hasOwnProperty.call(data, "sex")) {
            data.sex = data.sex === 0 ? 'M' : 'F'
        }


          console.log("post",data);
          return Api.post('quote',data,{headers:h})
     },
     getUWQuestions(data, token){
        // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im5pY2tAbGlmZW1pYi5jb20iLCJmbmFtZSI6Ik5pY2siLCJsbmFtZSI6IkR1ZGFqIiwicm9sZSI6MSwiaWF0IjoxNjI0ODg2NTcxLCJleHAiOjI2MjU0OTEzNzF9.5IkY4kkE4h59Zx5o0AchFGnoS3DmNsNps9fTqNxLfnE";
        const h = {Authorization: "Bearer " + token}
        return Api.get('entity/uw/status/' + JSON.stringify(data) ,{headers:h})

     },
}

function calculateAge(data){

    var dob = new Date(data.dob);
    var dob_month = dob.getMonth();

    var dob_year = dob.getFullYear();
    var curr_year = new Date();
    var curr_month = curr_year.getMonth();

    curr_year = curr_year.getFullYear();
    var age = curr_year - dob_year;

    if(curr_month<dob_month)
    age = age -1
    console.log("age",age, data.dob);
    return age;
}

function parseFilters (data){
    var criteria={}
    var non_criteria = {blank:true,is_uwquote:false}
    var fc ={$elemMatch:{}}

    if(Object.prototype.hasOwnProperty.call(data, "dob")) {
        criteria.rates = {$elemMatch:{age: calculateAge(data)}}
        fc.$elemMatch.age= non_criteria.age =calculateAge(data);
        criteria.rates = fc;
    }

    if(Object.prototype.hasOwnProperty.call(data, "tobacco")) {
        const tobacco = data.tobacco;
        const key = tobacco === 0 ? 'ns' : 'sm';
        criteria.rates = {$elemMatch:{[key]:{$gt:1}}}
        fc.$elemMatch[key] = {$gt:1};
        criteria.rates = fc;
        non_criteria.tobacco = tobacco;
        non_criteria.blank = false
    }

    if(Object.prototype.hasOwnProperty.call(data, "sex")) {
        const sex = data.sex === 0 ? 'M' : 'F'
        criteria.rates = {$elemMatch:{$or:[{sex:sex},{sex:'U'}]}}
        fc.$elemMatch['$or']=[{sex:sex},{sex:'U'}];
        criteria.rates = fc;
    }

    if(Object.prototype.hasOwnProperty.call(data, "product_category")) {
        const pc = data.product_category;
        criteria.product_category = pc;
    }

    if(Object.prototype.hasOwnProperty.call(data, "product_type")) {
        const pt = data.product_type;
        criteria.product_type = pt;
    }

    if(Object.prototype.hasOwnProperty.call(data, "faceamount")) {
        const fa = data.faceamount;
        non_criteria.faceamount =fa;
        non_criteria.blank = false

    }
    if(Object.prototype.hasOwnProperty.call(data, "state")) {
        non_criteria.state =data.state;
    }

    if(Object.prototype.hasOwnProperty.call(data, "uw")) {
        non_criteria.uw =data.uw;
    }

    if(Object.prototype.hasOwnProperty.call(data, "height")) {
        non_criteria.height =data.height;
    }

    if(Object.prototype.hasOwnProperty.call(data, "weight")) {
        non_criteria.weight =data.weight;
    }
    if(Object.prototype.hasOwnProperty.call(data, "is_uwquote")) {
        non_criteria.is_uwquote =data.is_uwquote;
    }

    criteria.status = 1;
    console.log("fc",fc);
    console.log( {criteria,non_criteria:non_criteria})

    return {criteria,non_criteria:non_criteria};
}
