<template>
      <v-card class="plancard">
        <div class="d-flex align-center justify-space-between pt-2">
                <h1
                    class="text-center d-inline-block text-truncate"
                    align="center"
                    justify="center"
                    style="font-size: 16px; max-width:90%;"
                  >
                    {{ plan.title }}

                  </h1>
                  <v-icon
                                      @click="isShowProdHigh=true;prodHighlights=plan.provider"

                    align="left"
                    color="light-blue"
                    class="text-left float-right"
                    style="margin-top: -10px; margin-right: -10px"
                    >mdi-clipboard-list</v-icon
                  >


        </div>


                     <h4  style="max-width:100%;font-size:45px;color:#000000;font-weight:bold">${{ROP}}</h4>

                      <h6  style="opacity:0.7;max-width:100%;font-size:13px;font-weight:400">Return of Premium</h6>


                  <h1
                    class="text-center display-1"
                    style="
                      color: #000000;
                      font-size: 40px !important;
                      margin-top: 10px;
                      font-weight: bold;
                    "
                  >
                    ${{ monthly_payment }}
                  </h1>
                  <h1
                    class="text-center"
                    style="
                      color: #999999;
                      font-size: 14px !important;
                      margin-top: 0px;
                      font-weight: 400;
                    "
                  >
                    per month
                  </h1>
                  <!-- <v-btn class="mt-5 apply-button" block depressed>APPLY</v-btn> -->
                  <v-btn class="mt-5 apply-button" block depressed :href="plan.provider.website" target="_blank">APPLY </v-btn>

    <v-dialog v-model="isShowProdHigh" persistent max-width="620" overlay-opacity="0.8">
            <v-card>

                <v-card-text>
                    <v-container class="text-center">
                      <img :src="prodHighlights.logo"/>
                      <h3>Roots since: <span style="color:#333333">{{prodHighlights.syear}}</span></h3>
                      <h3>Headquarters: <span style="color:#333333">{{prodHighlights.hq}}</span></h3>

                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error1" small @click="isShowProdHigh = false;prodHighlights={}">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


      </v-card>

</template>

<script>
export default {
    name: 'PlanCardROP',
    props: ['plan', 'type','faceamount','category'],
    data() {
        return {
            bgclass: 'background',
            plantype: '',
                     isShowProdHigh: false,
            prodHighlights:{}

        }
    },
    computed: {
        coverage_amount() {
            var fm  = this.faceamount.replace(/[^0-9]/g,'');

            var percentile = 1;

            if (this.type == 1)
                percentile = 0.43

            if (this.type == 2)
                percentile = 0.70

            return (fm * percentile).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        monthly_payment() {
            var fm = this.plan.monthly;
            var percentile = 1;

            if (this.type == 1)
                percentile = 0.43

            if (this.type == 2)
                percentile = 0.70

            return ((fm * percentile)).toFixed(2); //.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        ROP(){
          var pc = this.plan.product_category;
          pc = pc.replace(/[^0-9]/g,'');

          return parseFloat(parseInt(pc) * 12 * this.plan.monthly).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
    },
    mounted() {
        console.log("props", this.$props)
                this.prodHighlights = this.$props.plan.provider

    }
}
</script>
<style slot-scope>
.Bronze {
    background: #be8d42 !important
}

.Silver {
    background: #b8b7b7 !important
}

.Gold {
    background: #f0b803 !important;
}

.Platinum {
    background: #704d05 !important;
}

.no-gutters {
    margin-bottom: 10px !important;
}

.csx {
    border-radius: 5px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

}
</style>
