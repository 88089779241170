import { render, staticRenderFns } from "./PlanCardROP.vue?vue&type=template&id=13a2a112"
import script from "./PlanCardROP.vue?vue&type=script&lang=js"
export * from "./PlanCardROP.vue?vue&type=script&lang=js"
import style0 from "./PlanCardROP.vue?vue&type=style&index=0&id=13a2a112&prod&slot-scope=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports