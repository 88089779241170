import Vue from "vue";
import Router from "vue-router";
import IProposal from "@/components/IProposal";
import IProposalUW from "@/components/IProposalUW";

import IWLFE from "@/components/IWLFE";
import IWLFEUW from "@/components/IWLFEUW";

Vue.use(Router);

export default new Router({
  //mode: 'history',
  routes: [
    {
      path: "/",
      name: "home",
      component: IProposal,
    },
    {
      path: "/term",
      name: "term",
      component: IProposal,
    },
    {
      path: "/uw/term",
      name: "uwterm",
      component: IProposalUW,
    },
    {
      path: "/fe",
      name: "fe",
      component: IWLFE,
    },
    {
      path: "/uw/fe",
      name: "uwfe",
      component: IWLFEUW,
    },
  
  ],
});
