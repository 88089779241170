<template>
  <v-navigation-drawer
    v-model="realDrawer"
    width="260"
    app
    temporary
    right
    class="drawer-bg"
    @input="changedStatusToggle"
      >
    <template v-slot:prepend>
      <v-toolbar
        color="primary-bg"
        height="75"
        >
        <v-toolbar-title class="d-flex align-center pl-4">
             <a href="https://lifemib.com/pro/activities/leads/all" target="_blank">
           <img src="assets/img/Final.svg" alt="Logo" class="logo-img" @click="navTo('home')">
           </a>
        </v-toolbar-title>

        <v-spacer></v-spacer>

      </v-toolbar>
    </template>
    <!-------------User Info--------------->
    <!-- <v-list-item class="pa-2 pl-5" color="blue accent-2">
      <v-list-item-avatar>
        <v-avatar
          color="blue accent-3"
          size="40"
          >
          <img v-if="user.avatar !== '/'" :src="`${baseUrl}${user.avatar}`" :alt="user? user.name[0] : ''" />
          <span v-else class="white--text headline"> {{user? user.name[0] : ''}}</span>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-title>{{user? user.name : ''}}</v-list-item-title>
    </v-list-item> -->
    <!-------------User Info--------------->
    <v-divider></v-divider>
    <!----------------Menu------------------>
    <v-list>
        <v-list-item
            active-class="sub-header-active"
            link
            :to="term"
            >
            <!-- <v-list-item-icon>
                <img src="@/asset/img/drawer-uw-icon.png" alt="drawer-icon" class="drawer-mo-icon-img">
            </v-list-item-icon> -->
            <v-list-item-title class="">SP TERM</v-list-item-title>
        </v-list-item>
        <v-list-item
            active-class="sub-header-active"
            link
            :to="whole"
            >

            <!-- <v-list-item-icon>
                <img src="@/asset/img/drawer-learn-icon.png" alt="drawer-icon" class="drawer-mo-icon-img">
            </v-list-item-icon> -->
            <v-list-item-title class="">SP WHOLE</v-list-item-title>
        </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'DefaultDrawer',

    props: {
        drawer: {
            required: true,
            type: Boolean
        }
    },
  components: {
  },


  data: () => ({
      realDrawer: false,
      mpToken:'',
      term:'/',
      whole:'/fe',
      mainAppUrl: process.env.VUE_APP_MAIN_APP_URL
  }),
  computed:{
    currentPath(){
      return this.$route;
    }
  },

  created(){
    this.realDrawer = this.drawer
    if(this.currentPath.query.id){
      this.mpToken = this.currentPath.query.id
      this.term = this.term +'?id='+this.mpToken
      this.whole = this.whole +'?id='+this.mpToken
    }
  },

  watch:{
      drawer(val){
          if(val == true){
              this.realDrawer = true
          }
          else if (val == false){
              this.realDrawer = false
          }
      }
  },

  methods:{
    changedStatusToggle(val){
      if(val == false){
        this.$emit('onCloseDrawer', val)
      }
    },

    async logout () {
      // Log out the user.
      await this.$store.dispatch('auth/logout')
      // Redirect to login.
      this.$router.push({ name: 'login' })
    },



  }
}
</script>

<style lang="sass">
.logo-img
  width: 83px

#default-drawer
  .v-list-item
    margin-bottom: 8px

  .v-list-item::before,
  .v-list-item::after
    display: none

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 12px
    margin-bottom: 12px
    margin-left: 4px

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
</style>
