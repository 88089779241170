<template>
  <v-navigation-drawer
    v-model="drawer"
    width="360"
    app
    temporary
    right
    class="drawer-bg"
    @input="changedStatusToggle"
  >
    <template v-slot:prepend>
      <v-toolbar color="primary-bg" height="75">
        <v-toolbar-title class="d-flex align-center pl-4">
          <a
            href="https://lifemib.com/pro/activities/leads/all"
            target="_blank"
          >
            <img
              src="assets/img/Final.svg"
              alt="Logo"
              class="logo-img"
              @click="navTo('home')"
            />
          </a>
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
      <v-row
        class="justify-center mt-5 no-gutters"
      >
        <v-col cols="12" v-for="x in quoteResults" :key="x.id">
          <v-card class="text-center justify-center mycard ma-2">
            <v-icon
              v-if="x.noofrules"
              large
              color="red"
              v-bind="attrs"
              v-on="on"
              right
              class="float-right mr-2 mt-2"
              >mdi-alert-circle</v-icon
            >
            <v-card-text class="text-center">
              <h1
                style="font-size: 16px; font-weight: 400; padding: 0; margin: 0"
              >
                {{ x.provider.title }}
              </h1>
            </v-card-text>
            <h1 style="font-size: 20px; font-weight: 400; color: #ff9900">
              {{ x.title }}
            </h1>
            <v-card-title class="justify-center" v-if="!x.noofrules">
              <h3 class="display-1" style="color: #009900">${{ x.monthly }}</h3>
              <br />
            </v-card-title>
            <v-card-text v-if="!x.noofrules" class="text-center">
              Per Month
            </v-card-text>
            <v-card-text class="text-left">
              <span
                v-for="(r, indexz) in x.rules"
                style="color: red"
                :key="indexz"
              >
                <template v-if="!r.pass && r.ruleMessage.length > 2"
                  >{{ r.ruleMessage }}
                  <div
                    v-if="x.rules.length - 1 != indexz"
                    style="
                      border: 1px dotted #dddddd;
                      height: 1px;
                      width: 100%;
                      margin: 5px 0px 5px 0px;
                    "
                  ></div>
                </template>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "QuoteDrawer",

  props: {
    quoteResults: {
      type: Array,
      required: true
    },
    drawer: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    changedStatusToggle(val) {
      if (val == false) {
        this.$emit("onCloseQuoteDrawer", val);
      }
    },
  },
};
</script>

<style lang="sass">
.logo-img
  width: 83px

#default-drawer
  .v-list-item
    margin-bottom: 8px

  .v-list-item::before,
  .v-list-item::after
    display: none

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 12px
    margin-bottom: 12px
    margin-left: 4px

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
</style>
