<template>
  <div class="h-100">

    <div class="widget">


      <v-row
        class="row-wrapper"
        no-gutters
        style="height: 100% !important"
        fill-height
      >
        <v-col
          cols="12"
          md="4"
          style="background: white"
          class="lsec">
          <h2 class="text-center">SP WHOLE</h2>
          <v-form>
            <h3
              v-if="mystore.dob"
              class="text-center"
              style="color: #999999; font-weight: 400; font-size: 16px"
            >
              {{ calculateAge() }} - years
              <span v-if="month_old > -1"> - {{ month_old }} months </span>
              old
            </h3>

            <div class="mt-3">
              <v-text-field

                v-model="mystore.dob"
                label="*Date of Birth"
                outlined
                v-mask="'##/##/####'"
                placeholder="MM/DD/YYYY"
                hide-details=""
              ></v-text-field>
            </div>

            <div class="mt-3">
              <v-select
                hide-details
                v-model="mystore.state"
                outlined

                :items="state_items"
                label="*Select state"
                item-text="name"
                item-value="val"
              ></v-select>
            </div>

            <div class="mt-3">
              <v-select
                hide-details
                v-model="mystore.sex"
                outlined

                :items="[
                  { m: 'Male', v: 0 },
                  { m: 'Female', v: 1 },
                ]"
                label="*Select Gender"
                item-text="m"
                item-value="v"
              ></v-select>
            </div>

            <div class="mt-3">
              <v-select
                hide-details
                v-model="mystore.tobacco"
                outlined

                :items="[
                  { s: 'No', v: 0 },
                  { s: 'Yes', v: 1 },
                ]"
                label="*Tobacco products in the last 12 months?"
                item-text="s"
                item-value="v"
              ></v-select>
            </div>

            <div class="mt-3">
              <v-text-field
                hide-details

                required
                :rules="[numberRule]"
                v-model="mystore.faceamount"
                label="*Coverage Amount"
                outlined
                v-on:input="handleCoverageCostChange($event)"
              ></v-text-field>
            </div>

            <div class="mt-5" v-if="1 == 2">
              <v-select
                hide-details
                v-model="mystore.product_category"
                outlined

                :items="product_categories"
                label="Product Categories"
              ></v-select>
            </div>

            <v-btn
              large
              block
              class="mt-3"
              depressed
              color="black"
              dark
              @click="showProducts()"
            >
              <span v-if="btcap==false">SHOW Available products</span>
              <span v-if="btcap==true">UPDATE</span>


            </v-btn
            >

            <div>
              <div class="mt-5">
                <!-- <v-select
                  :disabled="!show_products"
                  hide-details
                  v-model="mystore.provider"
                  outlined

                  :items="uniqueProviders"
                  label="Select Provider"
                  item-text="provider.title"
                  item-value="provider"
                  @change="filterProductsOnChange()"
                ></v-select>
                 -->
                <v-select
                  v-if="marketToken == '' || marketToken == null || marketToken == undefined"
                  :disabled="!show_products"
                  hide-details
                  v-model="mystore.provider"
                  outlined

                  :items="uniqueProviders"
                  label="Select Provider"
                  item-text="provider.title"
                  item-value="provider"
                  @change="filterProductsOnChange()"
                  :menu-props="{ top: true, offsetY: true }"
                ></v-select>
                <v-select
                  v-else
                  :disabled="!show_products"
                  hide-details
                  v-model="mystore.provider"
                  outlined

                  :items="uniqueProviders"
                  label="Select Provider"
                  item-text="sp_provider_title"
                  item-value="sp_provider_id"
                  @change="filterProductsOnChange()"
                  :menu-props="{ top: true, offsetY: true }"
                ></v-select>
              </div>
              <div class="mt-3">
                <v-select
                  :disabled="!show_products"
                  hide-details
                  v-model="mystore.product_id"
                  outlined

                  :items="providerProducts"
                  label="Select Product"
                  item-text="title"
                  item-value="_id"
                  @change="findQuote()"
                  :menu-props="{ top: true, offsetY: true }"
                ></v-select>
                <div>
                  <v-btn
                    max-width="100%"
                    width="49%"
                    :disabled="products.length <= 0"
                    depressed
                    color="black"
                    :dark="!(products.length <= 0)"
                    class="mt-5 float-left"
                    @click="planTypeIndex = 1"
                  >LM PROPOSAL
                  </v-btn
                  >
                  <v-btn
                    width="49%"
                    :disabled="products.length <= 0"
                    depressed
                    color="black"
                    :dark="!(products.length <= 0)"
                    class="mt-5 float-right"
                    @click="planTypeIndex = 4"
                  >COMPARE
                  </v-btn
                  >
                </div>
              </div>
            </div>
          </v-form>
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="rsec hidden-sm-and-down"
          :class="(products.length < 1) && $vuetify.breakpoint.smAndDown ? ' order-first d-none' : (products.length < 1) ? '' : 'px-30'"
          align="center"
          justify="center"
          :height="heightx"
        >
          <v-container
            no-gutters
            align="center"
            justify="center"
            class="fill-height"
            :class="products.length < 1 ? 'pa-0' : ''"
            fluid
            v-if="uniqueProviders.length < 1"
          >
            <!-- <h3 style="width: 100%" v-if="products.length < 1">
              No Results!!!
            </h3> -->

            <!-- <div v-if="products.length < 1" style="width: 100%; height: 100%;">
                <iframe src="https://my.visme.co/view/pvywdope-sp-whole#s1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="width: 100%; height: 100%;"></iframe>
              </div> -->

            <!-- <div v-if="products.length < 1" class="advertise-con align-center justify-center" :class="$vuetify.breakpoint.smAndDown ? ' pa-5' : 'd-flex'">
              <p class=" font-weight-bold text-h5 text-md-h3 dark-blue--text text-capitalize" :class="$vuetify.breakpoint.smAndDown ? '':'position-absolute'" :style="$vuetify.breakpoint.smAndDown ? '':'top: 30px; left: 30px'">Did you know?</p>
              <p class=" text-h4 text-md-h2 dark-p-blue--text px-md-16">More than $87 billion in death benefits were paid out in 2020</p>
              <p class=" font-weight-bold white--text" :class="$vuetify.breakpoint.smAndDown ? '':'position-absolute'" :style="$vuetify.breakpoint.smAndDown ? '': 'bottom: 30px; left: 30px;'">*<a href="https://www.iii.org" class="white--text" target="blank">Source</a> : https://www.iii.org</p>
            </div> -->
            <div v-if="products.length < 1" class="advertise-con align-center justify-center"
                 :class="$vuetify.breakpoint.smAndDown ? '' : 'd-flex'">
<!--              <img src="assets/img/1.png" class="w-100 h-100"/>-->
            </div>

            <!-- <video-player
              v-if="products.length < 1"
              class="video-player-box vjs-custom-skin w-100"
              ref="videoPlayer"
              :options="playerOptions"
              :playsinline="true"
              >
            </video-player> -->
          </v-container>

          <div v-else-if="show_products" :class="products.length > 0 ? '' : 'h-100 d-flex align-center'">
            <div class="w-100" :class="products.length < 1 ? 'h-100' : ''">
              <v-alert
                :value="alertDelay"

                type="info"
                icon="mdi-robot"
                transition="scale-transition"
                class="mt-0 mx-2 py-0 pl-0 custom-alert"
                dismissible
                color="#E1B03C"
                dark
                prominent
                close-text="close"
                v-if="products.length > 0"
              >
                <h3 class="white--text">Disclaimer, monthly premiums are subject to change. Click Apply for final
                  rate.</h3>
              </v-alert>

              <!-- <div v-if="products.length < 1" style="width: 100%; height: 100%;">
                <iframe src="https://my.visme.co/view/pvywdope-sp-whole#s1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="width: 100%; height: 100%;"></iframe>
              </div> -->
              <!-- <div v-if="products.length < 1" class="advertise-con align-center justify-center" :class="$vuetify.breakpoint.smAndDown ? ' pa-5' : 'd-flex'">
                <p class=" font-weight-bold text-h5 text-md-h3 dark-blue--text text-capitalize " :class="$vuetify.breakpoint.smAndDown ? '':'position-absolute'" :style="$vuetify.breakpoint.smAndDown ? '':'top: 30px; left: 30px;'">Costly Mistakes To Avoid</p>
                <div>
                  <p class=" text-h4 text-md-h3 dark-p-blue--text px-md-16 mb-10 text-center">1. Waiting too long</p>
                  <p class=" text-h4 text-md-h3 dark-p-blue--text px-md-16 mb-10 text-center">2. Picking a policy based only on price</p>
                  <p class=" text-h4 text-md-h3 dark-p-blue--text px-md-16 mb-10 text-center">3. Choosing the wrong type of insurance</p>
                </div>
              </div> -->

              <div v-if="(products.length < 1) && isQuoteOnce == false"
                   class="advertise-con align-center justify-center"
                   :class="$vuetify.breakpoint.smAndDown ? '' : 'd-flex'">
<!--                <img src="assets/img/2.png" class="w-100 h-100"/>-->
              </div>
              <div v-else-if="(products.length < 1 ) && isQuoteOnce == true"
                   class="advertise-con align-center justify-center"
                   :class="$vuetify.breakpoint.smAndDown ? ' pa-5' : 'd-flex'">
                <p class=" text-h4 text-md-h3 dark-p-blue--text px-md-16 mb-10 text-center">Please select provider and
                  product.</p>
              </div>
              <!-- <video-player
                v-if="products.length < 1"
                class="video-player-box vjs-custom-skin w-100"
                ref="videoPlayer"
                :options="playerOptionsAfterSubmit"
                :playsinline="true"
                >
              </video-player> -->
            </div>

            <div v-if="products.length > 0">
              <!-- <h1 class="display-1 mb-5" style="opacity: 0.7">
                {{ planType[planTypeIndex] }}
              </h1> -->

              <ul class="card-wrapper" v-if="planTypeIndex == 1">
                <li v-for="x in 3" :key="x">
                  <planCardE
                    :plan="products[0]"
                    :faceamount="mystore.faceamount"
                    :type="x"
                    :key="x._id + 1"
                  />
                </li>
              </ul>
              <ul class="card-wrapper" v-if="planTypeIndex == 2">
                <li v-for="p in products" :key="p._id">
                  <v-card class="plancard" v-if="planTypeIndex == 2">
                    <v-icon
                      align="left"
                      color="light-blue"
                      class="text-left float-right"
                      style="margin-top: -10px; margin-right: -10px"
                    >mdi-clipboard-list
                    </v-icon
                    >

                    <h1
                      class="text-center d-inline-block text-truncate"
                      align="center"
                      justify="center"
                      style="font-size: 16px; padding-top: 10px; max-width: 90%"
                    >
                      {{ p.title }}
                    </h1>
                    <!-- <span
                      class="d-inline-block text-truncate"
                      style="opacity: 0.7; max-width: 100%; font-size: 13px"
                      >{{ p.provider.title }}</span
                    > -->

                    <h1
                      class="text-center display-1"
                      style="
                        color: #000000;
                        font-size: 40px !important;
                        margin-top: 10px;
                        font-weight: bold;
                      "
                    >
                      ${{ p.monthly }}
                    </h1>
                    <h1
                      class="text-center"
                      style="
                        color: #999999;
                        font-size: 14px !important;
                        margin-top: 0px;
                        font-weight: 400;
                      "
                    >
                      per month
                    </h1>
                    <v-btn class="mt-5 apply-button" block depressed>APPLY</v-btn>
                  </v-card>
                </li>
              </ul>
              <ul class="card-wrapper" v-if="planTypeIndex == 3">
                <li>
                  <planCardROP
                    :plan="products[0]"
                    :faceamount="mystore.faceamount"
                    :type="3"
                  />
                </li>
              </ul>
            </div>

            <div v-if="products.length > 0 && planTypeIndex == 4">
              <div class="mt-5">
                <v-select
                  hide-details
                  v-model="productToCompare"
                  outlined

                  :items="uniqueCategories"
                  :menu-props="{ top: false, offsetY: true }"
                  color="#F1BD40"
                  label="Choose a product to compare"
                  append-icon="mdi-chevron-double-down"
                  item-text="title"
                  item-value="val"
                  @change="compareProducts()"
                ></v-select>

                <v-data-table
                  :headers="headers"
                  :items="itemsToCompare"
                  :items-per-page="-1"
                  hide-default-footer
                  class="mt-5 mytable"
                >
                  <template v-slot:item="{item}">
                    <tr v-if="item.monthly" class="myrow">
                      <td>
                        <img :src="item.provider.logo" width="150px" />
                      </td>

                      <td>
                        <h3>{{ item.title }}</h3>
                        <h5 style="clear: both; color: #777777">
                          {{ item.provider.title }}
                        </h5>
                      </td>

                      <td>
                        {{ item.premium_type }}
                      </td>

                      <td>
                        <h3 v-if="item.monthly" style="color:#ff6600">${{ item.monthly }}</h3>
                      </td>

                    </tr>
                  </template>


                </v-data-table>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-overlay absolute="absolute" :value="overlay">
      <v-card color="white">
        <div
          style="
            margin: 0px auto;
            padding-top: 10px !important;
            width: 100%;
            text-align: center;
          "
        >
          <v-progress-circular
            :size="70"
            :width="7"
            color="purple"
            indeterminate
          ></v-progress-circular>
        </div>

        <v-card-title style="color: #333333"> Loading...</v-card-title>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
const config = require("../../config");
import planCardE from "../components/PlanCardE.vue";
import planCardROP from "../components/PlanCardROP.vue";

//video viewer
import 'video.js/dist/video-js.css'
// import { videoPlayer } from 'vue-video-player'

import quoteAPI from "../backend/quote";
import mpAPI from "../backend/mpProvider";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const _ = require("lodash");

const currencyMask = createNumberMask({
  prefix: "$",
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: "IQuote",
  components: {
    planCardE: planCardE,
    planCardROP: planCardROP,
    // videoPlayer,
  },
  data() {
    return {
      isQuoteOnce: false,
      btcap: false,
      headers: [
        {text: "Logo", value: "provider", width: "150"},
        {text: "Title", value: "title"},
        {text: "Type", value: "product_category"},
        {text: "Monthly", value: "monthly"},
      ],
      overlay: false,
      items: [],
      toggle_exclusive: 2,
      sex1: 0,
      mystore2: "",
      planTypeIndex: 1,
      planType: ["", "E-Proposal", "All Terms", "Return of Premium", "Compare"],
      alertDelay: false,
      show_products: false,
      uniqueProviders: [],
      mpWholeProviders: [],
      marketToken: '',
      providerProducts: [],
      termProducts: [],
      ropProducts: [],
      products: [],
      uniqueCategories: [],
      itemsToCompare: [],
      productToCompare: undefined,
      mystore: {
        dob: "",
        tobacco: null,
        sex: null,
        state: null,
        faceamount: "",
        product_category: undefined,
        provider: undefined,
        product_id: undefined,
        sessionType: "sp"
      },
      mystore_org: {
        dob: undefined,
        tobacco: undefined,
        sex: undefined,
        state: undefined,
        faceamount: undefined,
        product_category: undefined,
        provider: undefined,
        product_id: undefined,
        sessionType: "sp"
      },
      productCount: 0,
      quoteResults: [],
      quoteResultsByProvider: [],
      mask: currencyMask,
      state_items: config.states,
      premium_items: [{
        title: "Not Applicable",
        val: "NA"
      },
        {
          title: "Standard",
          val: "SPT"
        },
        {
          title: "Preferred",
          val: "PPT"
        },
        {
          title: "Graded - Modified",
          val: "GMT"
        },
        {
          title: "Guaranteed",
          val: "GPT"
        }
      ],
      product_categories: [
        "Term-5",
        "Term-10",
        "Term-15",
        "Term-20",
        "Term-25",
        "Term-30",
        "Term-5-ROP",
        "Term-10-ROP",
        "Term-15-ROP",
        "Term-20-ROP",
        "Term-25-ROP",
        "Term-30-ROP",
        "WLFE",
      ],


      month_old: "",
      numberRule: (v) => {
        if (v == "$0" || v == "" || v == null || v == "$") {
          return "Should be greater than zero";
        } else return true;
      },
      playerOptions: {
        // videojs options
        height: '450',
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
        }],
      },
      playerOptionsAfterSubmit: {
        // videojs options
        height: '450',
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
        }],
      },
    };
  },

  created() {
    this.playerOptions.sources[0].src = `assets/video/sample.mp4`;
    this.playerOptionsAfterSubmit.sources[0].src = `assets/video/sample1.mp4`;
    if (this.currentPath.query.id) {
      this.marketToken = this.currentPath.query.id;
      console.log("marketToken: ", this.marketToken);
      mpAPI.get(this.marketToken, {
        recordType: this.currentPath.query.recordType,
        recordId: this.currentPath.query.recordId
      }).then(res => {
        this.mpTermProviders = res.data.sp_term_providers;
        this.mpWholeProviders = res.data.sp_whole_providers;
        console.log('------mpTermProviders--------', this.mpTermProviders)
        console.log('------mpWholeProviders--------', this.mpWholeProviders)
        if(res.recordResult) {
          const state = res.recordResult.state ? res.recordResult.state.toUpperCase() : '';
          const genderValue = (this.extractValueFromData('gender', res.recordResult.custom_fields) && (this.extractValueFromData('gender', res.recordResult.custom_fields).toLowerCase() === 'male' && '0' )) || (this.extractValueFromData('gender', res.recordResult.custom_fields) && (this.extractValueFromData('gender', res.recordResult.custom_fields).toLowerCase() === 'female' && '1' ));
          let currentSelectedState = config.states.find(itm => [itm.name.toUpperCase(), itm.val.toUpperCase()].includes(state))
          this.mystore.state = currentSelectedState;
          this.mystore.dob = res.recordResult.dateOfBirth && new Date(res.recordResult.dateOfBirth).toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
          this.mystore.sex = parseInt(genderValue);
          const tobaccoValue = res.recordResult.custom_fields && (this.extractValueFromData('tobacco', res.recordResult.custom_fields) === "YES" ? '1': '0') || "";
          this.mystore.tobacco = parseInt(tobaccoValue);
          const coverageValue = (res.recordResult.coverageAmount && `${parseInt(res.recordResult.coverageAmount).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0 })}`) || (parseInt(this.extractValueFromData('coverage', res.recordResult.custom_fields)) && `${parseInt(this.extractValueFromData('coverage', res.recordResult.custom_fields)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0 })}`) || '';
          this.mystore.faceamount = coverageValue;
        }
      }).catch(err => {
        console.log('============', err)
      });
    } else {
      window.location.href = `${process.env.VUE_APP_MAIN_APP_URL}/sign-in`
    }
  },

  methods: {
    handleCoverageCostChange(value) {
      if(value.length >= 17) return;

      if(!value.includes('$')) {
        console.debug('if value', {value})
        this.mystore.faceamount = `$${value}`;
        return;
      }
      const formattedValue = value.split('$')?.[1].replaceAll(',', '');
      if (formattedValue) {
        console.debug('if 2 value', {formattedValue})
        const coverageValue = `${parseInt(formattedValue).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
        this.mystore.faceamount = coverageValue;
      } else {
        console.debug('else value', {value})
        this.mystore.faceamount = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    calculateAge() {
      var dob = new Date(this.mystore.dob);
      var dob_month = dob.getMonth() + 1;
      var dob_year = dob.getFullYear();
      var curr_year = new Date();
      var curr_month = curr_year.getMonth() + 1;

      curr_year = curr_year.getFullYear();
      var age = curr_year - dob_year;

      if (curr_month < dob_month) {
        age = age - 1;
        this.month_old = 12 - dob_month + curr_month;
      } else this.month_old = curr_month - dob_month;

      return age;
    },
    filterProducts() {
      console.log("mystore", this.mystore);
      var obj = {...this.mystore};
      console.log("passwed-1", obj);

      Object.keys(obj).forEach(
        (key) => (obj[key] === undefined || obj[key] === "") && delete obj[key]
      );
      console.log("passwed-2", obj);
      quoteAPI
        .get(obj, this.marketToken)
        .then((x) => {
          this.items = x.data.msg;
          this.productCount = x.data.msg;
          console.log("items", this.items);
        })
        .catch((e) => {
          console.log("getAll():", e);
        });
    },
    showProducts() {
      /**
       * Validate Birthday
       */
      if (this.mystore.dob < 10) {
        return this.$vToastify.error('Please type birthday', 'Require')
      }
      let now = new Date();
      let birthdayYear = parseInt(this.mystore.dob.split('/')[2])
      let birthdayMonth = parseInt(this.mystore.dob.split('/')[0])
      let birthdayDay = parseInt(this.mystore.dob.split('/')[1])
      if (birthdayYear < 1900) {
        return this.$vToastify.error('Birthday year should be greater than 1900')
      }
      if (birthdayYear > now.getFullYear()) {
        return this.$vToastify.error(`Birthday year should be smaller than current year ${now.getFullYear()}`)
      }
      if (birthdayMonth > 12 || birthdayMonth < 1) {
        return this.$vToastify.error('Please input correct birthday month')
      }
      if (birthdayDay > 31 || birthdayDay < 1) {
        return this.$vToastify.error('Please input correct birthday day')
      }

      /**
       * Validate State
       */
      if (this.mystore.state == null) {
        return this.$vToastify.error('Please select State', 'Require')
      }
      /**
       * Validate Gender
       */
      if (this.mystore.sex == null) {
        return this.$vToastify.error('Please select Gender', 'Require')
      }
      /**
       * Validate Tobacco
       */
      if (this.mystore.tobacco == null) {
        return this.$vToastify.error('Please select answer of nicotine related question', 'Require')
      }
      /**
       * Validate FaceAmount
       */
      if (this.mystore.faceamount == "") {
        return this.$vToastify.error('Please type coverage amount', 'Require')
      }

      this.btcap = true;
      this.overlay = true;
      this.products = [];
      this.providerProducts = [];
      // this.uniqueProviders = [];
      this.planTypeIndex = 1;
      this.mystore.product_type = "Whole Life/Final Expense";
      this.items = [];
      // this.mystore.provider = undefined;
      var obj = {...this.mystore};
      Object.keys(obj).forEach(
        (key) => (obj[key] === undefined || obj[key] === "") && delete obj[key]
      );
      console.log("obj", obj);
      quoteAPI
        .get(obj, this.marketToken)
        .then((x) => {
          this.items = x.data.msg;
          this.productCount = x.data.msg;
          console.log("items", this.items);
          this.quoteResults = x.data.msg;

          this.uniqueProviders = [];

          this.uniqueProviders = _.orderBy(_.filter(_.uniqBy(this.items, "provider.title"), {rule_pass: true}), ['provider.title'], ['asc'])
          this.itemsToCompare = _.filter(_.orderBy(_.filter(this.items, {rule_pass: true}), ['monthly'], ['asc']), (item) => !item.title.includes('UW') )
          this.uniqueCategories = _.orderBy(_.filter(_.uniqBy(this.items, "premium_type"), {rule_pass: true}), ['premium_type'], ['asc'])
          //this.uniqueCategories =  _.orderBy(_.filter(_.uniqBy(this.items, "premium_type"),{rule_pass:true}),['premium_type'],['asc'])
          this.uniqueCategories = _.filter(this.premium_items, (item) => _.find(this.uniqueCategories, {premium_type: item.val}))

          let mpproviders = []
          this.mpWholeProviders.map(item => {
            mpproviders.push(item.sp_provider_title)
          })
          console.log('---------', mpproviders)
          let compareItems = []
          if (mpproviders.length > 0) {
            mpproviders.map(item => {
              this.itemsToCompare.map(comItem => {
                if (comItem.provider.title == item) {
                  compareItems.push(comItem);
                }
              });
            });
            this.itemsToCompare = compareItems.filter(item => !item.title.includes("UW") && item.monthly);
          }
          console.log("itemstocompare", this.itemsToCompare);
          this.products = [];
          this.providerProducts = [];
          if (this.marketToken == undefined || this.marketToken == '' || this.marketToken == null) {
            console.log("this.marketToken", this.marketToken)
          } else {
            this.uniqueProviders = this.mpWholeProviders
          }
          console.log("UQ", this.uniqueProviders);
          console.log("products", this.products);
          this.show_products = true;
          this.overlay = false;


          this.uniqueProviders = this.uniqueProviders.map((item) => {
            const total = _.filter(x.data.msg, (itm) => itm['provider']['_id'] === item.sp_provider_id);
            const available = _.filter(x.data.msg, (itm) => (itm['provider']['_id'] === item.sp_provider_id && itm['rule_pass'] == true));
            const unavailable = _.filter(x.data.msg, (itm) => (itm['provider']['_id'] === item.sp_provider_id && itm['rule_pass'] == false));

            return {
              ...item,
              sp_provider_title: `${item.sp_provider_title || ''}`,
              title: `${item.title || ''}`,
              total: total.length,
              available: available.length,
              unavailable: unavailable.length
            }
          })
          if(this.mystore.provider) {
            this.filterProductsOnChange();
          }
        })
        .catch((e) => {
          console.log("getAll():", e);
          this.overlay = false;
        });
    },
    filterProductsOnChange() {
      console.log("this.mystore.provider", this.mystore.provider);
      this.planTypeIndex = 1;
      this.products = [];
      // this.mystore.product_id = "";

      // this.providerProducts = _.orderBy([
      //   ...this.items.filter(
      //     (item) =>
      //       item.provider._id === this.mystore.provider._id &&
      //       !item.product_category.includes("ROP")
      //   ),
      // ],['product_category'],['asc'])

      if (this.marketToken == '' || this.marketToken == null || this.marketToken == undefined) {
        this.providerProducts = _.orderBy(
          [
            ...this.items.filter(
              (item) =>
                item.provider._id === this.mystore.provider._id &&
                !item.product_category.includes("ROP")
            ),
          ],
          ["product_category"],
          ["asc"]
        );

      } else {
        this.providerProducts = _.orderBy(
          [
            ...this.items.filter(
              (item) =>
                item.provider._id === this.mystore.provider &&
                !item.product_category.includes("ROP")
            ),
          ],
          ["product_category"],
          ["asc"]
        );

      }

      console.log("filterchnae", this.providerProducts);
      this.providerProducts = this.providerProducts.map((item) => {
        return {
          ...item,
          title: `${item.title} ${item.rule_pass ? "" : "- No Rates Available"}`,
        }
      })

      if(this.mystore.product_id) {
        this.findQuote();
      }
    },
    findQuote() {
      this.isQuoteOnce = true;
      this.mystore.provider_id = this.mystore.provider._id;
      this.overlay = true;
      this.alertDelay = false;
      quoteAPI
        .getQuote(this.mystore, this.marketToken)
        .then((x) => {
          if (!x.data.success) {
            this.overlay = false;
            this.$vToastify.error(`${x.data.msg}`, 'Usage Limit')
            return false;
          }
          this.products = _.filter(_.orderBy(x.data.msg, ['product_category'], ['desc']), (itm) => (!itm.title.includes("UW")))
          this.overlay = false;
          const failedItem = this.quoteResults.filter((item) => item != undefined && item.rule_pass == false && this.mystore.product_id === `${item._id}` && `${item.title || ''}`.toLowerCase().indexOf('uw') === -1);
          if (failedItem.length) {
            this.$emit('openQuoteDrawer', true);
          }

          this.$emit('setQuoteResults', failedItem);

          if (this.products.length > 0) {
            // alert(this.products[0].title);

            if (this.products[0].product_category.includes("ROP"))
              this.planTypeIndex = 3;
            else this.planTypeIndex = 1;

            setTimeout(() => {
              this.alertDelay = true;
            }, 3000);
          }
          console.log("findQuote()", x);
        })
        .catch((ex) => {
          this.overlay = false;
          console.log("ex", ex);
        });
    },
    showAllTerms() {
      this.mystore.product_id = "";
      this.mystore.provider_id = this.mystore.provider._id;
      this.mystore.product_type = "Term";
      this.planTypeIndex = 2;
      this.overlay = true;
      quoteAPI
        .getQuote(this.mystore, this.marketToken)
        .then((x) => {
          console.log("x", x);
          this.overlay = false;
          this.providerProducts = this.products = _.orderBy([
            ...x.data.msg.filter(
              (item) =>
                item.provider._id === this.mystore.provider._id &&
                !item.product_category.includes("ROP") &&
                item.monthly > 0
            ),
          ], ['title'], ['desc'])
          // this.products = x.data.msg;
          console.log(x);
        })
        .catch((ex) => {
          this.overlay = false;
          console.log("ex", ex);
        });
    },
    showAllROPs() {
      this.mystore.product_id = "";
      this.mystore.provider_id = this.mystore.provider._id;
      this.overlay = true;
      this.mystore.product_type = "Term";
      quoteAPI
        .getQuote(this.mystore, this.marketToken)
        .then((x) => {
          this.overlay = false;
          this.providerProducts = this.products = x.data.msg.filter((item) => {
            console.log("inside", item.title);
            item.product_category.includes("ROP")
              ? console.log("ROP", item.title)
              : console.log("NOROP", item.title);
            return item.product_category.includes("ROP") && item.monthly > 0;
          });

          console.log(x);
        })
        .catch((ex) => {
          this.overlay = false;
          console.log("ex", ex);
        });
    },
    compareProducts() {
      this.itemsToCompare = this.items.filter(
        (item) => item.premium_type === this.productToCompare && !item.title.includes("UW") && item.monthly
      );
    },

    extractValueFromData(searchKey, data) {
      let value = null;
      Object.keys(data).forEach(dataKey => {
        const resultMatched = dataKey.toLowerCase().includes(searchKey.toLowerCase());
        const newValue = data[dataKey];
        if (resultMatched) {
          value = typeof newValue === 'string' ? newValue.trim() : newValue;
        }
      });
      return value;
    }
  },
  watch: {
    mystore2: {
      handler(val) {
        console.log("mystore", val);
        var obj = {...val};
        Object.keys(obj).forEach(
          (key) =>
            (obj[key] === undefined || obj[key] === "") && delete obj[key]
        );
        quoteAPI
          .get(obj, this.marketToken)
          .then((x) => {
            this.items = x.data.msg;
            this.productCount = x.data.msg;
            console.log("items", this.items);
          })
          .catch((e) => {
            console.log("getAll():", e);
          });
      },
      deep: true,
    },
  },
  computed: {
    heightx() {
      var h = '93vh';
      var bp = this.$vuetify.breakpoint.name;
      if (bp == 'xs' || bp == 'sm')
        h = '100%';

      return h;
    },
    hasROP() {
      /*  console.log("products",this.products);
      console.log("providerProducts", this.providerProducts)
      return this.providerProducts.filter((item) => {
          return item.product_category.includes("ROP");
      }).length > 0 ? true : false
    */

      return this.items.filter(
        (item) =>
          item.provider._id === this.mystore.provider._id &&
          item.product_category.includes("ROP")
      ).length > 0
        ? true
        : false;
    },
    player() {
      return this.$refs.videoPlayer.player
    },
    currentPath() {
      return this.$route;
    },
  },
};
</script>

<style slot-scope>
.inner-container {
  margin: 20px 30px 30px 30px;
}

.h-without-header {
  height: calc(100vh - 75px);
}

.h-100 {
  height: 100%;
}

.row-wrapper {
}

.list-title {
  color: #333333 !important;
  font-weight: 600;
  text-decoration: none !important;
}

.widget {
  box-shadow: 0 0 3px #cccccc;
  background: #f5f8fb;
  padding: 0px;
  border-radius: 5px;
  border: 0px solid #ffffff;
  background: white !important;
  height: 100%;
}

.lsec {
  border-right: 1px dashed #cccccc;
  padding-top: 0px !important;
  padding: 50px 20px 100px !important;
}

.rsec {
  background: #f0f0f0;
  /* padding: 30px !important; */
}

.px-30 {
  padding: 30px !important;
}

.card-wrapper {
  margin: 0 !important;
  padding: 0 !important;
  margin-top: 50px;
}

.card-wrapper li {
  list-style: none;
  display: inline-block;
  width: 50%;
  padding: 10px;
}

.plancard {
  padding: 20px;
  /* margin: 5px; */
  width: 100%;
}

.fx {
  transition: all 5s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.loader {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #000000;
  z-index: 50 !important;
  opacity: 0.1;
}

.loaderdiv {
  z-index: 60 !important;
  width: 100% !important;
  border: 4px solid #ff0000;
  position: relative;
  opacity: 1 !important;

}

.mytable {
  border: 4px solid #011f61;
  background: #b9cffd;
}

.myrow td {
  border: 4px solid #4f5050;
  border-left: none;
  border-right: none;
  padding: 10px 10px !important;
  margin: 30px !important;
}

.myrow {
  padding: 50px !important;
  background: #dfdfdf !important;
  margin: 100px !important;
}

.advertise-con {
  width: 100%;
  height: 100%;
  /* background: rgb(237,49,64);
  background: linear-gradient(45deg, rgba(237,49,64,1) 0%, rgba(252,186,89,1) 100%); */
  position: relative;
  padding: 0px 200px 200px;
  background: #fff;
}

.position-absolute {
  position: absolute !important;
}

.dark-blue--text {
  color: #264FA8
}

.dark-p-blue--text {
  color: #142E59
}
</style>
