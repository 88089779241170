import axios from "axios";
// const baseUrl = 'http://94.158.21.216:3000/api/'
// const baseUrl = 'https://lifemib.com/api'
const baseUrl = `${process.env.VUE_APP_API_URL}`; //'https://lifemib.com/api'
// const baseUrl = 'http://localhost:3000/api/'
export default {
  async get(token, { recordType, recordId } = {}) {
    //    const h =  {"Authorization" : `authorization ${token}`}

    //    console.log('h--------',h)
    //    return Api.get('profile',{headers:h})
    // axios.interceptors.request.use(function (config) {
    //     config.headers.Authorization =  token;

    //     return config;
    // });
    axios.defaults.headers.common["Authorization"] = token;

    const profileResult = await axios.get(`${baseUrl}/profile`)
    // return axios.get(`${baseUrl}/profile`);

    let finalResult = {data: {...profileResult.data }}

      if (recordType && recordId) {
        if (recordType === 'lead') {
          const recordResult = await axios.get(`${baseUrl}/leads/${recordId}`)
          finalResult = {...finalResult, recordResult: recordResult.data.lead}
        } else if (recordType === 'appointment') {
          const recordResult = await axios.get(`${baseUrl}/appointments/${recordId}`)
          finalResult = {...finalResult, recordResult: recordResult.data.appointments}
        } else if (recordType === 'deal') {
          const recordResult = await axios.get(`${baseUrl}/deals/${recordId}`)
          finalResult = {...finalResult, recordResult: recordResult.data.deal}
        }
      }

      return finalResult

  },
  async helpful(token, payload) {
    axios.defaults.headers.common["Authorization"] = token;
    await axios
      .post(`${baseUrl}/helpful`, payload)
      .then(res => {
        console.log("helpful success", res);
      })
      .catch(err => {
        console.log(err);
      });
    return true;
  }
};
