<template>
  <v-app>
    <AppHeader :notification="!!quoteResults.length" @openDrawer="openDrawer" @openQuoteDrawer="openQuoteDrawer" />
    <v-main class="main-bg">
      <router-view
        :drawer="quoteDrawer"
        @openQuoteDrawer="openQuoteDrawer"
        @onCloseQuoteDrawer="onCloseQuoteDrawer"
        @setQuoteResults="setQuoteResults"
      />
    </v-main>
    <AppDrawer :drawer="drawer" @onCloseDrawer="onCloseDrawer" />
    <QuoteDrawer
      :quoteResults="quoteResults"
      :drawer="quoteDrawer"
      @onCloseQuoteDrawer="onCloseQuoteDrawer"
    />
  </v-app>
</template>

<script>
import AppHeader from "./components/AppHeader";
import AppDrawer from "./components/AppDrawer";
import QuoteDrawer from "./components/AppDrawer/QuoteDrawer";

export default {
  name: "App",
  data: () => ({
    drawer: false,
    quoteDrawer: false,
    quoteResults: []
  }),
  components: {
    AppHeader,
    AppDrawer,
    QuoteDrawer,
  },
  methods: {
    openDrawer() {
      this.drawer = true;
    },
    onCloseDrawer() {
      this.drawer = false;
    },

    openQuoteDrawer() {
      this.quoteDrawer = true;
    },
    onCloseQuoteDrawer() {
      this.quoteDrawer = false;
    },
    setQuoteResults(data) {
      this.quoteResults = data;
    },
  },
};
</script>
<style>
.main-bg {
  background: #f0ebeb !important;
}
</style>
