<template>
  <div class="inner-container" style="height: 95%">
    <div class="widget" style="height: 100%">
      <v-row
        class="row-wrapper"
        no-gutters
        style="height: 100% !important"
        fill-height
      >
        <v-col cols="4" style="background: white" class="lsec">
          <h2 class="text-center">Underwriting - Term</h2>
          <v-form>
            <h3
              v-if="mystore.dob"
              class="text-center"
              style="color: #999999; font-weight: 400; font-size: 16px"
            >
              {{ calculateAge() }} - years
              <span v-if="month_old > -1"> - {{ month_old }} months </span>
              old
            </h3>

            <v-row>
              <v-col class="mt-3">
                <v-text-field
                  dense
                  v-model="mystore.dob"
                  label="Date of Birth"
                  filled
                  v-mask="'##/##/####'"
                  placeholder="MM/DD/YYYY"
                  hide-details=""
                ></v-text-field>
              </v-col>

              <v-col class="mt-3">
                <v-select
                  hide-details
                  v-model="mystore.sex"
                  filled
                  dense
                  :items="[
                    { m: 'Male', v: 0 },
                    { m: 'Female', v: 1 },
                  ]"
                  label="Select Gender"
                  item-text="m"
                  item-value="v"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pt-0">
                <v-select
                  hide-details
                  v-model="mystore.tobacco"
                  filled
                  dense
                  :items="[
                    { s: 'No', v: 0 },
                    { s: 'Yes', v: 1 },
                  ]"
                  label="Used any nicotine in the past 24 months?"
                  item-text="s"
                  item-value="v"
                ></v-select>
              </v-col>

              <v-col class="pt-0">
                <v-text-field
                  hide-details
                  dense
                  required
                  :rules="[numberRule]"
                  v-model="mystore.faceamount"
                  label="Coverage Amount"
                  v-mask="mask"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pt-0" cols="3">
                <v-text-field
                  label="Height"
                  filled
                  v-mask="'#.##'"
                  v-model="mystore.height"
                  required
                  :rules="valRequired"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col class="pt-0" cols="3">
                <v-text-field
                  dense
                  hide-details
                  label="Weight (lbs)"
                  filled
                  v-model="mystore.weight"
                  v-mask="'####'"
                  required
                  :rules="valRequired"
                ></v-text-field>
              </v-col>
              <v-col class="pt-0">
              <v-select
                v-if="1==2"
                hide-details
                v-model="mystore.state2"
                filled
                dense
                :items="state_items"
                label="Select state"
                item-text="name"
                item-value="val"
              ></v-select>


              </v-col>
            </v-row>

            <!--Medical -->
            <v-row>
              <v-col class="pt-0">
                <v-autocomplete
                  eager
                  label="Medical History"
                  color="orange"
                  v-model="mystore.medical"
                  :items="medical_items"
                  filled
                  item-text="title"
                  item-value="_id"
                  placeholder="Type or Search"
                  @change="addMedicalItem"
                  hide-details
                  hide-selected
                  ref="el"
                  id="mdhist"
                  return-object
                ></v-autocomplete>

                <v-data-table
                  v-if="medical_items_table.length > 0"
                  :headers="headers"
                  :items="medical_items_table"
                  hide-default-footer
                  disable-pagination
                >
                  <template slot="item" scope="props">
                    <tr>
                      <td>
                        {{ props.item.title }}
                      </td>
                      <td>
                        <v-text-field
                          v-if="props.item.qtype == 1"
                          small
                          outlined
                          dense
                          v-model="props.item.answer"
                          label="When treated?"
                          v-mask="'##/##/####'"
                          placeholder="MM/DD/YYYY"
                          hide-details=""
                          width="50px"
                          class="float-right"
                        ></v-text-field>

                        <span v-if="props.item.qtype==2" class="float-right">
                          {{sayYES(props)}}
                        </span>

                      </td>

                      <td width="50px">
                        <v-icon
                          title="Delete"
                          color="red"
                          style="font-size: 20px; margin-left: 10px"
                          @click="medical_items_table.splice(props.index, 1)"
                          >mdi-trash-can-outline</v-icon
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>


            <!--Persciption -->
            <v-row>
              <v-col class="pt-0">
                <div style="width:100% !important;">
                <v-text-field
                  style="width:85%"
                  label="Drug Perscription"
                  color="orange"
                  v-model="perscription"
                  filled
                  hide-details
                  hide-selected
                  class="float-left"
                ></v-text-field>
              <v-btn depressed outlined @click="addPerscriptionItem()" class="float-right" style="height:55px !important">
                   <v-icon color="blue">mdi-plus-circle</v-icon>
              </v-btn>
                </div>
                <table
                  v-if="perscription_items_table.length > 0"
                >
                    <tr v-for="(i,dindex) in perscription_items_table" :key="i">
                      <td style="width:90%">
                        {{i }}
                      </td>

                      <td width="60px" class="float-right">
                         <v-icon
                          title="View Drug Details"
                          color="blue"
                          style="font-size: 20px; margin-left: 10px"
                          @click="showDrugDetails(i)"
                          >mdi-pill</v-icon
                        >
                        <v-icon
                          title="Delete"
                          color="red"
                          style="font-size: 20px; margin-left: 10px"
                          @click="perscription_items_table.splice(dindex, 1)"
                          >mdi-trash-can-outline</v-icon
                        >
                      </td>
                    </tr>
                    </table>
              </v-col>
            </v-row>




            <!--Personal -->
            <v-row>
              <v-col class="pt-0">
                <v-autocomplete
                  eager
                  label="Personal History"
                  color="orange"
                  v-model="mystore.personal"
                  :items="personal_items"
                  filled
                  item-text="title"
                  item-value="_id"
                  placeholder="Type or Search"
                  @change="addPersonalItem"
                  hide-details
                  hide-selected
                  return-object
                ></v-autocomplete>
                <v-data-table
                  v-if="personal_items_table.length > 0"
                  :headers="headers"
                  :items="personal_items_table"
                  hide-default-footer
                >
                  <template slot="item" scope="props">
                    <tr>
                      <td>
                        {{ props.item.title }}
                      </td>
                      <td>
                         <v-text-field
                          v-if="props.item.qtype == 1"
                          small
                          outlined
                          dense
                          v-model="props.item.answer"
                          label="When treated?"
                          v-mask="'##/##/####'"
                          placeholder="MM/DD/YYYY"
                          hide-details=""
                          width="50px"
                          class="float-right"
                        ></v-text-field>

                        <span v-if="props.item.qtype==2" class="float-right">
                          {{sayYES(props)}}
                        </span>

                      </td>

                      <td width="50px">
                        <v-icon
                          title="Delete"
                          color="red"
                          style="font-size: 20px; margin-left: 10px"
                          @click="personal_items_table.splice(props.index, 1)"
                          >mdi-trash-can-outline</v-icon
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <!--Lifestyle -->
            <v-row v-if="1==2">
              <v-col class="pt-0">
                <v-autocomplete
                  eager
                  label="Lifestyle History"
                  color="orange"
                  v-model="mystore.lifestyle"
                  :items="lifestyle_items"
                  filled
                  item-text="title"
                  item-value="_id"
                  placeholder="Type or Search"
                  @change="addLifestyleItem"
                  hide-details
                  hide-selected
                  return-object
                ></v-autocomplete>
                <v-data-table
                  v-if="lifestyle_items_table.length > 0"
                  :headers="headers"
                  :items="lifestyle_items_table"
                  hide-default-footer
                >
                  <template slot="item" scope="props">
                    <tr>
                      <td>
                        {{ props.item.title }}
                      </td>
                      <td>
                      <span v-if="props.item.qtype==2" class="float-right">
                          {{sayYES(props)}}
                        </span>

                      </td>

                      <td width="50px">
                        <v-icon
                          title="Delete"
                          color="red"
                          style="font-size: 20px; margin-left: 10px"
                          @click="lifestyle_items_table.splice(props.index, 1)"
                          >mdi-trash-can-outline</v-icon
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <!--Occupation -->
            <v-row v-if="1==2">
              <v-col class="pt-0">
                <v-autocomplete
                  eager
                  label="Occupation History"
                  color="orange"
                  v-model="mystore.occupation"
                  :items="occupation_items"
                  filled
                  item-text="title"
                  item-value="_id"
                  placeholder="Type or Search"
                  @change="addOccupationItem"
                  hide-details
                  hide-selected
                  return-object
                ></v-autocomplete>
                <v-data-table
                  v-if="occupation_items_table.length > 0"
                  :headers="headers"
                  :items="occupation_items_table"
                  hide-default-footer
                >
                  <template slot="item" scope="props">
                    <tr>
                      <td>
                        {{ props.item.title }}
                      </td>
                      <td>
                       <span v-if="props.item.qtype==2" class="float-right">
                          {{sayYES(props)}}
                        </span>

                      </td>

                      <td width="50px">
                        <v-icon
                          title="Delete"
                          color="red"
                          style="font-size: 20px; margin-left: 10px"
                          @click="occupation_items_table.splice(props.index, 1)"
                          >mdi-trash-can-outline</v-icon
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  large
                  block
                  class="mt-3"
                  depressed
                  color="orange"
                  @click="
                    btcap = true;
                    showProducts();
                  "
                >
                  <span v-if="btcap == false">SHOW Available products</span>
                  <span v-if="btcap == true">UPDATE</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>

        <v-col cols="8" class="rsec">

            <ul class="uw_legends" v-if="1==2">
            <li>
              <v-icon  color="green">
                mdi-check-circle
              </v-icon>
              <span>
                Underwriting Passed
              </span>
            </li>
            <li>
              <v-icon
                color="red"
              >
                mdi-close-circle
              </v-icon>
              <span>Declined</span>
            </li>
            <li>
              <v-icon
                color="orange"
              >
                mdi-information
              </v-icon>
              <span>Partially declined. </span>
            </li>
            <li>
                 <v-icon v-if="1==2"
                      color="blue accent-4"
                    >
                      mdi-help-circle
                    </v-icon>
                  <span>No Underwriting restrictions defined by the Provider</span>
            </li>
          </ul>

          <v-data-table :headers="result_headers" :items="uw_results" hide-default-footer disable-pagination>
            <template v-slot:item.provider.logo="{ item }">
              <img :src="item.provider.logo" max-width="100%" width="100px" />
            </template>
            <template v-slot:item.hw="{ item }">
              <v-icon v-if="item.hw == true" color="green" >
                mdi-check-circle
              </v-icon>
              <v-icon v-if="item.hw == false" color="red">
                mdi-close-circle
              </v-icon>
            </template>

            <template v-slot:item.medical="{ item }">
              <v-icon v-if="parseUW(item, 1) == 1" color="green" @click="
                  showUWMessage = true;
                  UWMessages(item, 1);
                ">
                mdi-check-circle
              </v-icon>
              <v-icon v-if="parseUW(item, 1) == 2" color="red"  @click="
                  showUWMessage = true;
                  UWMessages(item, 1);
                ">
                mdi-close-circle
              </v-icon>
              <v-icon v-if="parseUW(item, 1) == 3" color="orange" @click="
                  showUWMessage = true;
                  UWMessages(item, 1);
                ">
                mdi-information
              </v-icon>
                <v-icon
                      v-if="parseUW(item, 1) == 1000"
                      color="blue accent-4"
                    >
                      mdi-help-circle
                    </v-icon>
            </template>

            <template v-slot:item.personal="{ item }">
              <v-icon v-if="parseUW(item, 2) == 1" color="green" @click="
                  showUWMessage = true;
                  UWMessages(item, 2);
                ">
                mdi-check-circle
              </v-icon>
              <v-icon v-if="parseUW(item, 2) == 2" color="red"  @click="
                  showUWMessage = true;
                  UWMessages(item, 2);
                ">
                mdi-close-circle
              </v-icon>
              <v-icon v-if="parseUW(item, 2) == 3" color="orange"  @click="
                  showUWMessage = true;
                  UWMessages(item, 2);
                ">
                mdi-information
              </v-icon>
                <v-icon
                      v-if="parseUW(item, 1) == 1000"
                      color="blue accent-4"
                    >
                      mdi-help-circle
                    </v-icon>
            </template>

            <template v-slot:item.lifestyle="{ item }" v-if="1==2">
              <v-icon v-if="parseUW(item, 3) == 1" color="green">
                mdi-check-circle
              </v-icon>
              <v-icon
                v-if="parseUW(item, 3) == 2"
                color="red"
                @click="
                  showUWMessage = true;
                  UWMessages(item, 3);
                "
              >
                mdi-close-circle
              </v-icon>
              <v-icon v-if="parseUW(item, 3) == 3" color="orange" @click="
                  showUWMessage = true;
                  UWMessages(item, 3);
                ">
                mdi-information
              </v-icon>
            </template>

            <template v-slot:item.occupation="{ item }">
              <v-icon v-if="parseUW(item, 5) == 1" color="green">
                mdi-check-circle
              </v-icon>
              <v-icon v-if="parseUW(item, 5) == 2" color="red" @click="
                  showUWMessage = true;
                  UWMessages(item, 5);
                ">
                mdi-close-circle
              </v-icon>
              <v-icon v-if="parseUW(item, 5) == 3" color="orange" @click="
                  showUWMessage = true;
                  UWMessages(item, 5);
                ">
                mdi-information
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>

    <v-overlay absolute="absolute" :value="overlay">
      <v-card color="white">
        <div
          style="
            margin: 0px auto;
            padding-top: 10px !important;
            width: 100%;
            text-align: center;
          "
        >
          <v-progress-circular
            :size="70"
            :width="7"
            color="purple"
            indeterminate
          ></v-progress-circular>
        </div>

        <v-card-title style="color: #333333"> Loading... </v-card-title>
      </v-card>
    </v-overlay>

    <!-- UWMessage -->
    <v-row justify="center">
      <v-dialog
        v-model="showUWMessage"
        persistent
        max-width="1200px"
        overlay-opacity="0.8"
      >
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <v-col>
                <span class="headline">Underwritting Conditions</span>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-data-table
                dense
                :headers="uwMessage_headers"
                :items="uwMessage_Items"
                hide-default-footer
                class="list-rate"
              >
              <template v-slot:item.qcat="{item}">
                  {{
                      parseUWCat(item.qcat)
                  }}
              </template>
                <template v-slot:item.upass="{ item }">
                  <v-icon v-if="item.upass == true" color="green">
                    mdi-check-circle
                  </v-icon>
                  <v-icon v-if="item.upass == false" color="red">
                    mdi-close-circle
                  </v-icon>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="error1"
              small
              @click="
                showUWMessage = false;
                uwMessage_Items = [];
              "
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

<!-- Drug Details View -->
    <v-row justify="center">
      <v-dialog
        v-model="isShowDrugDetails"
        persistent
        max-width="600px"
        overlay-opacity="0.8"
      >
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <v-col>
                <span class="headline">Drug Details</span>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-container>
              <ul

                class="list-rate"
              >
              <li v-for="x in perscription_items" :key="x">
                <span>[{{x.rxclassMinConceptItem.className}}] in [{{x.rxclassMinConceptItem.classType}}] [{{x.rela}}] </span>
              </li>
              </ul>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              small
              @click="
                isShowDrugDetails = false;
                perscription_items = [];
              "
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>


  </div>
</template>

<script>
const config = require("../../config");
import quoteAPI from "../backend/quote";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
const _ = require("lodash");

const currencyMask = createNumberMask({
  prefix: "$",
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: "IQuote",
  data() {
    return {
      uw_items: [],
      perscription:'',
      drugsearch:null,
      medical_items: [],
      perscription_items: [],
      isShowDrugDetails: false,
      personal_items: [],
      lifestyle_items: [],
      occupation_items: [],
      headers: [
        { text: "Title", value: "title" },
        { text: "", value: "uyear", align: "end", width: "150px" },
        { text: "", value: "action" },
      ],

      medical_items_table: [],
      perscription_items_table: [],

      personal_items_table: [],
      lifestyle_items_table: [],
      occupation_items_table: [],
      uwMessage_Items: [],
      showUWMessage: false,
      uw_results : [],

      result_headers: [
        { text: "Provider", value: "provider.logo" },
        { text: "Product", value: "title", sortable:true },
        { text: "Height", value: "hw",sortable:true },
        { text: "Medical", value: "medical",sortable:true },
        { text: "Personal", value: "personal",sortable:true },
      ],

      uwMessage_headers: [
        { text: "Type", value: "qcat", align: "start" },
        { text: "Title", value: "title", align: "start" },
        { text: "Message", value: "msg", align: "middle" },
        { text: "", value: "upass", align: "end" },
      ],

      btcap: false,

      overlay: false,
      items: [],
      toggle_exclusive: 2,
      sex1: 0,
      mystore2: "",
      planTypeIndex: 1,
      planType: ["", "E-Proposal", "All Terms", "Return of Premium", "Compare"],
      alertDelay: false,
      show_products: false,
      uniqueProviders: [],
      providerProducts: [],
      termProducts: [],
      ropProducts: [],
      products: [],
      uniqueCategories: [],
      itemsToCompare: [],
      productToCompare: undefined,
      mystore: {
        dob: "12/12/1980",
        tobacco: 0,
        sex: 0,
  //      state: "KS",
        faceamount: 50000,
        product_category: undefined,
        provider: undefined,
        product_id: undefined,
        others: undefined,
      },
      mystore_org: {
        dob: undefined,
        tobacco: undefined,
        sex: undefined,
   //     state: undefined,
        faceamount: undefined,
        product_category: undefined,
        provider: undefined,
        product_id: undefined,
      },
      productCount: 0,
      mask: currencyMask,
      state_items: config.states,
      product_categories: [
        "Term-5",
        "Term-10",
        "Term-15",
        "Term-20",
        "Term-25",
        "Term-30",
        "Term-5-ROP",
        "Term-10-ROP",
        "Term-15-ROP",
        "Term-20-ROP",
        "Term-25-ROP",
        "Term-30-ROP",
        "WLFE",
      ],
      valRequired: [(v) => !!v || "This field is required"],

      month_old: "",
      numberRule: (v) => {
        if (v == "$0" || v == "" || v == null || v == "$") {
          return "Should be greater than zero";
        } else return true;
      },
    };
  },
  methods: {
    calculateAge() {
      var dob = new Date(this.mystore.dob);
      var dob_month = dob.getMonth() + 1;
      var dob_year = dob.getFullYear();
      var curr_year = new Date();
      var curr_month = curr_year.getMonth() + 1;

      curr_year = curr_year.getFullYear();
      var age = curr_year - dob_year;

      if (curr_month < dob_month) {
        age = age - 1;
        this.month_old = 12 - dob_month + curr_month;
      } else this.month_old = curr_month - dob_month;

      return age;
    },
    filterProducts() {
      console.log("mystore", this.mystore);
      var obj = { ...this.mystore };
      console.log("passwed-1", obj);

      Object.keys(obj).forEach(
        (key) => (obj[key] === undefined || obj[key] === "") && delete obj[key]
      );
      console.log("passwed-2", obj);
      quoteAPI
        .get(obj)
        .then((x) => {
          this.items = x.data.msg;
          this.productCount = x.data.msg;
          console.log("items", this.items);
        })
        .catch((e) => {
          console.log("getAll():", e);
        });
    },
    showProducts() {
      this.overlay = true;
      this.products = [];
      this.providerProducts = [];
      this.uniqueProviders = [];
      this.planTypeIndex = 1;
      this.mystore.product_type = "Term";
      this.items = [];
      this.mystore.provider = undefined;
      this.mystore.is_uwquote = true;
      this.mystore.uw = []
        .concat(this.medical_items_table)
        .concat(this.personal_items_table)
        .concat(this.lifestyle_items_table)
        .concat(this.occupation_items_table);
      var obj = { ...this.mystore };
      Object.keys(obj).forEach(
        (key) => (obj[key] === undefined || obj[key] === "") && delete obj[key]
      );
      console.log("obj", obj);
      quoteAPI
        .get(obj)
        .then((x) => {
          this.items = x.data.msg;
          console.log("items",this.items)
          if(x.data.msg.length > 0){
            this.uw_results = x.data.msg.filter((item)=>item.rule_pass==true);
          //  this.uw_results = x.data.msg.filter((item)=>item.rule_pass==true && Object.prototype.hasOwnProperty.call(item, "uw") && item.uw.length > 0);

          }
          this.productCount = x.data.msg;
          console.log("uw_results", this.uw_results);

          this.uniqueProviders = _.orderBy(
            _.filter(_.uniqBy(this.items, "provider.title"), {
              rule_pass: true,
            }),
            ["provider.title"],
            ["asc"]
          );
          this.uniqueCategories = _.orderBy(
            _.uniqBy(this.items, "product_category"),
            ["product_category"],
            ["asc"]
          );

          this.itemsToCompare = this.items.filter(item => !item.title.includes('UW'));
          console.log("itemstocompare", this.itemsToCompare);
          this.products = [];
          this.providerProducts = [];
          console.log("UQ", this.uniqueProviders);
          console.log("products", this.products);
          this.show_products = true;
          this.overlay = false;
        })
        .catch((e) => {
          console.log("getAll():", e);
          this.overlay = false;
        });
    },
    filterProductsOnChange() {
      console.log("this.mystore.provider", this.mystore.provider);
      this.planTypeIndex = 1;
      this.products = [];
      this.mystore.product_id = "";

      this.providerProducts = _.orderBy(
        [
          ...this.items.filter(
            (item) =>
              item.provider._id === this.mystore.provider._id &&
              !item.product_category.includes("ROP")
          ),
        ],
        ["product_category"],
        ["asc"]
      );

      console.log("filterchnae", this.providerProducts);
    },
    findQuote() {
      this.mystore.provider_id = this.mystore.provider._id;
      this.overlay = true;
      quoteAPI
        .getQuote(this.mystore)
        .then((x) => {
          this.products = _.filter(_.orderBy(x.data.msg, ["product_category"], ["desc"]), (itm) => (!itm.title.includes("UW")));
          this.overlay = false;
          if (this.products.length > 0) {
            // alert(this.products[0].title);

            if (this.products[0].product_category.includes("ROP"))
              this.planTypeIndex = 3;
            else this.planTypeIndex = 1;

            setTimeout(() => {
              this.alertDelay = true;
            }, 2000);
          }
          console.log("findQuote()", x);
        })
        .catch((ex) => {
          this.overlay = false;
          console.log("ex", ex);
        });
    },
    showAllTerms() {
      this.mystore.product_id = "";
      this.mystore.provider_id = this.mystore.provider._id;
      this.mystore.product_type = "Term";
      this.planTypeIndex = 2;
      this.overlay = true;
      quoteAPI
        .getQuote(this.mystore)
        .then((x) => {
          console.log("x", x);
          this.overlay = false;
          this.providerProducts = this.products = _.orderBy(
            [
              ...x.data.msg.filter(
                (item) =>
                  item.provider._id === this.mystore.provider._id &&
                  !item.product_category.includes("ROP")
              ),
            ],
            ["title"],
            ["desc"]
          );

          // this.products = x.data.msg;
          console.log(x);
        })
        .catch((ex) => {
          this.overlay = false;
          console.log("ex", ex);
        });
    },
    showAllROPs() {
      this.mystore.product_id = "";
      this.mystore.provider_id = this.mystore.provider._id;
      this.overlay = true;
      this.mystore.product_type = "Term";
      quoteAPI
        .getQuote(this.mystore)
        .then((x) => {
          this.overlay = false;
          this.providerProducts = this.products = x.data.msg.filter((item) => {
            console.log("inside", item.title);
            item.product_category.includes("ROP")
              ? console.log("ROP", item.title)
              : console.log("NOROP", item.title);
            return item.product_category.includes("ROP");
          });

          console.log(x);
        })
        .catch((ex) => {
          this.overlay = false;
          console.log("ex", ex);
        });
    },
    compareProducts() {
      this.itemsToCompare = this.items.filter(
        (item) => item.product_category === this.productToCompare &&  !item.title.includes('UW')
      );
    },
    loadUWQuestions() {
      const criteria = {
        status: 1,
      };
      quoteAPI
        .getUWQuestions(criteria)
        .then((x) => {
          var t = x.data.msg;
          return (this.uw_items =
            t.length > 0 ? t.filter((item) => item.ptype === "Term") : []);
        })
        .then((v) => {
          this.medical_items = v.filter((item) => item.qcat === 1);
          this.personal_items = v.filter((item) => item.qcat === 2);
          this.lifestyle_items = v.filter((item) => item.qcat === 3);
          this.occupation_items = v.filter((item) => item.qcat === 5);
        })
        .catch((ex) => {
          this.overlay = false;
          console.log("ex", ex);
        });
    },
    addMedicalItem() {
      this.medical_items_table.push(this.mystore.medical);
      this.mystore.medical = {};
    },
   addPerscriptionItem() {
      this.perscription_items_table.push(this.perscription);
      this.perscription='';
    },

    addPersonalItem() {
      this.personal_items_table.push(this.mystore.personal);
      this.mystore.personal = {};
    },
    addLifestyleItem() {
      this.lifestyle_items_table.push(this.mystore.lifestyle);
      this.mystore.lifestyle = {};
    },
    addOccupationItem() {
      this.occupation_items_table.push(this.mystore.occupation);
      this.mystore.occupation = {};
    },

    parseUW(uw, utype) {
      var icon = 100;
      if (Object.prototype.hasOwnProperty.call(uw, "uw")) {
        if (uw.uw.length > 0) {
          console.log("uw", uw.uw);
          var all_false = uw.uw.filter(
            (item) => item.upass == false && item.qcat == utype
          ).length;
          var all_true = uw.uw.filter(
            (item) => item.upass == true && item.qcat == utype
          ).length;

          if (all_true > 0) icon = 1;

          if (all_false > 0) icon = 2;

          if (all_true > 0 && all_false > 0) icon = 3;
          console.log(all_true, all_false, icon);
          return icon;
        } else return icon;
      } else return icon;
    },
    UWMessages(uw, utype) {
      this.uwMessage_Items = uw.uw;
      console.log(utype)
      /*
      this.uwMessage_Items = [];
      if (Object.prototype.hasOwnProperty.call(uw, "uw")) {
        if (uw.uw.length > 0) {
          this.uwMessage_Items = [
            ...uw.uw.filter(
              (item) => item.upass == false && item.qcat == utype
            ),
          ];
          console.log("UWMessage", this.uwMessage_Items);
        }
      }*/

    },
    parseUWCat(c){

              var x=["",
                              "Medical",
                              "Personal",
                              "Lifestyle",
                              "Perscription",
                              "Occupation",
                              "Payment"
                        ]
              return x[c]

    },
    sayYES(props){
          props.item.answer = 'Yes';
          //return props.item.answer
    },
    shx(x){
      console.log("x",x);
    },
    showDrugDetails(val){
      this.isShowDrugDetails = true;
      var url = `https://rxnav.nlm.nih.gov/REST/rxclass/class/byDrugName.json?drugName=${val}&relaSource=MEDRT&relas=may_treat+may_prevent`;
      fetch(url)
                .then(res => res.json())
                .then(res => {
                    console.log("res:", res);
                    //const { count, entries } = res
                    this.perscription_items = res.rxclassDrugInfoList.rxclassDrugInfo;
                    console.log("this.perscriptions_items",this.perscription_items)
                    //this.isLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    //this.isLoading = false;
                    this.perscription = null;
                })

    }
  },
  watch: {
    mystore2: {
      handler(val) {
        console.log("mystore", val);
        var obj = { ...val };
        Object.keys(obj).forEach(
          (key) =>
            (obj[key] === undefined || obj[key] === "") && delete obj[key]
        );
        quoteAPI
          .get(obj)
          .then((x) => {
            this.items = x.data.msg;
            this.productCount = x.data.msg;
            console.log("items", this.items);
          })
          .catch((e) => {
            console.log("getAll():", e);
          });
      },
      deep: true,
    },
    drugsearch(val) {
            // Items have already been loaded
            console.log("val", val);
            ////this.isLoading = false;

            if (val == null || val == "" || val.length < 3) return;

            ////this.isLoading = true

            // Lazily load input items
            var url = `https://rxnav.nlm.nih.gov/REST/rxclass/spellingsuggestions.json?type=DRUG&term=${val}`;
            fetch(url)
                .then(res => res.json())
                .then(res => {
                    console.log("res:", res);
                    //const { count, entries } = res
                    this.perscription_items = res.suggestionList.suggestion;
                    //this.isLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    //this.isLoading = false;
                    this.perscription = null;
                })
                //.finally(() => (
                  ///this.isLoading = false
               // ))
        },
  },
  computed: {
    heightx() {
      var h = "93vh";
      var bp = this.$vuetify.breakpoint.name;
      if (bp == "xs" || bp == "sm") h = "100%";

      return h;
    },
    hasROP() {
      /*  console.log("products",this.products);
      console.log("providerProducts", this.providerProducts)
      return this.providerProducts.filter((item) => {
          return item.product_category.includes("ROP");
      }).length > 0 ? true : false
    */

      return this.items.filter(
        (item) =>
          item.provider._id === this.mystore.provider._id &&
          item.product_category.includes("ROP")
      ).length > 0
        ? true
        : false;
    },
  },
  mounted() {
    this.loadUWQuestions();
  },
};
</script>

<style slot-scope>
.inner-container {
  margin: 20px 30px 30px 30px;
}
.row-wrapper {
}
.list-title {
  color: #333333 !important;
  font-weight: 600;
  text-decoration: none !important;
}

.widget {
  box-shadow: 0 0 3px #cccccc;
  background: #f5f8fb;
  padding: 0px;
  border-radius: 5px;
  border: 0px solid #ffffff;
  background: white !important;
  height: 100%;
}
.lsec {
  border-right: 1px dashed #cccccc;
  padding-top: 0px !important;
  padding: 20px !important;
}
.rsec {
  background: #f0f0f0;
  padding: 30px !important;
}
.csec {
  border: 4px solid #ff0000;
  width: 100% !important;
}
.card-wrapper {
  margin: 0 !important;
  padding: 0 !important;
  margin-top: 50px;
}
.card-wrapper li {
  list-style: none;
  display: inline-block;
}
.plancard {
  padding: 20px;
  margin: 5px;
  width: 280px;
}
.fx {
  transition: all 5s ease;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.loader {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #000000;
  z-index: 50 !important;
  opacity: 0.1;
}
.loaderdiv {
  z-index: 60 !important;
  width: 100% !important;
  border: 4px solid #ff0000;
  position: relative;
  opacity: 1 !important;
}

.mytable {
  border: 4px solid #011f61;
  background: #b9cffd;
}
.myrow td {
  border: 4px solid #4f5050;
  border-left: none;
  border-right: none;
  padding: 10px 10px !important;
  margin: 30px !important;
}
.myrow {
  padding: 50px !important;
  background: #dfdfdf !important;
  margin: 100px !important;
}
.hcolor {
  color: #fda622 !important;
  font-size: 50px !important;
}
.uw_legends{
  padding:20px;
  border:2px dashed #011f61;
  margin-bottom:20px;
}
.uw_legends li {
  list-style:none;
  display:inline !important;
  margin-left:10px;
}
</style>
